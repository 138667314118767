import copy from "copy-text-to-clipboard";
import toast from "react-hot-toast";
import { ClipboardListDuo } from "components/svgs";
import { preventSimulatedEventonIOS } from "components/menu-buttons";
import { ExplorerStore } from "explorer/store";
import { observer } from "mobx-react-lite";
import { autorun, makeAutoObservable, reaction } from "mobx";

let hasLS = false;
try {
  const hasLSObject =
    Object.keys(window).includes("localStorage") && typeof localStorage;
  if (hasLSObject) {
    localStorage.setItem("verifyLS", "verifyLS");
    localStorage.removeItem("verifyLS");
    hasLS = true;
  }
} catch (_e) {}

class CopyFacetStore {
  enabled = hasLS && !!localStorage.getItem("copyfacets");
  constructor() {
    makeAutoObservable(this);
    window.addEventListener("hashchange", this.hashListener);
    setTimeout(this.hashListener, 50); // timeout to also show initial enabling

    if (hasLS)
      autorun(() => {
        if (this.enabled) {
          localStorage.setItem("copyfacets", "true");
        } else {
          localStorage.removeItem("copyfacets");
        }
      });

    reaction(
      () => this.enabled,
      (enabled) => toast(`Copy Facets feature ${enabled ? "en" : "dis"}abled.`),
    );
  }

  hashListener = () => {
    this.enabled =
      document.location.hash.includes("copyfacets") &&
      !document.location.hash.includes("copyfacets=false");
  };
}

const copyFacetStore = new CopyFacetStore();

const attackedCountDuplicate = (store, depth: number) => {
  const attacked_count =
    store.meili.facetDistribution["attacked_count"]["0"] !== undefined
      ? null
      : Object.entries(store.meili.facetDistribution["attacked_count"]).reduce(
          (acc, [a, b]) => {
            // "0" Should not happen, because we don't display the component in this case
            if (a === "0") {
              return acc;
            }

            // this catches count-exclusions like "general_law"
            const num = Number(a);
            if (isNaN(num)) {
              return acc;
            }

            // the actual result
            return acc + num * b;
          },
          0,
        );

  return `Alert Count\t\t${"\t".repeat(depth)}${
    store.meili.lastResponse.estimatedTotalHits
  }\nAttacked Count\t\t${"\t".repeat(depth)}${
    attacked_count
      ? attacked_count
      : "not applicable because alerts before 2020 included"
  }`;
};

function childrenToTabsIndentedText(
  store: ExplorerStore,
  section: any,
  option: any,
  depth: number,
  maxDepth: number,
) {
  if (!option.children || option.children.length < 1) return "";
  const inverseDepth = Math.max(maxDepth - depth, 0);
  return option.children
    .map(
      (child) =>
        `\n\t${"\t".repeat(depth)}${child.label}\t${"\t".repeat(inverseDepth)}${
          store.meili.facetDistribution[section.id][child.value] ?? 0
        }${childrenToTabsIndentedText(store, section, child, depth + 1, maxDepth)}`,
    )
    .join("");
}

export const CopyFacetDataButton = observer(function CopyFacetDataButton({
  classNames = "",
  store,
}: {
  classNames?: string;
  store: ExplorerStore;
}) {
  if (!copyFacetStore.enabled) return null;
  const maxDepth = 6;
  const counts =
    !!store.meili.lastResponse && attackedCountDuplicate(store, maxDepth);
  const facets = store.filtersForUI
    .map(
      (section) =>
        `${store.dimensionLabel(section.id)}\n${section.options
          .map(
            (option) =>
              `\t${
                option.value == "separator"
                  ? `--- ${option.label} ---`
                  : `${option.label}\t${"\t".repeat(maxDepth)}${
                      store.meili.facetDistribution[section.id][option.value] ??
                      0
                    }${childrenToTabsIndentedText(store, section, option, 1, maxDepth)}`
              }`,
          )
          .join("\n")}`,
    )
    .join("\n\n");
  const str = `${counts}\n\n${facets}`;
  return (
    <a
      href="#"
      class={`${classNames} inline-block rounded px-2 pb-2 pt-1`}
      title="Copy all facet counts to clipboard"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        preventSimulatedEventonIOS(() => {
          copy(str);
          toast.success("The facet counts were copied to the clipboard!");
        });
      }}
    >
      <ClipboardListDuo />
    </a>
  );
});
