import { observer } from "mobx-react-lite";

export const Tag = ({ tag }: { tag: string }) => (
  <span class="ml-2 inline-block">
    <svg
      id="tag"
      viewBox="0 0 8 8"
      class="m-2 inline-block h-3 w-3"
      style={{ fill: "currentColor" }}
    >
      <path d="M0 0v3l5 5 3-3-5-5h-3zm2 1c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z" />
    </svg>
    {tag}
  </span>
);

const TagChildren = ({
  tag,
  alertTags,
}: {
  tag: InitTag;
  alertTags: string[];
}) =>
  tag.children && (
    <div class={"ml-8"}>
      {tag.children
        ?.filter((stoi) => alertTags.includes(stoi.label))
        ?.filter((stoi) => !stoi.hide_on_details)
        .map((stoi) => (
          <div key={stoi.label} class="mt-2">
            <Tag
              key={stoi.label}
              tag={stoi.label.replace(`${tag.label} `, "")}
            />
            <TagChildren tag={stoi} alertTags={alertTags} />
          </div>
        ))}
    </div>
  );
export const NestedTags = observer(function DetailsView({
  topSlug,
  tags,
}: {
  topSlug: string;
  tags: string[];
}) {
  return (
    <>
      {window.initData.tags
        .find((tag) => tag.slug == topSlug)
        .children.filter((child) => tags.includes(child.label))
        .map((currentTag) => (
          <div key={currentTag.label} class="mt-2">
            <Tag key={currentTag.label} tag={currentTag.label} />
            <TagChildren tag={currentTag} alertTags={tags} />
          </div>
        ))}
    </>
  );
});
