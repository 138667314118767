/*
RegionBound v2.0.4 -- region-aware marker clustering for Leaflet

Adds cluster tooltips, heuristic hull hover, cluster aggregation functions
and clusters of one.

Copyright 2014-2020 Rik de Boer. Proprietry software. All rights reserved.
Copy and distribution of this software is prohibited.
For info and map projects email us at regionbound@gmail.com 

This software is based on the work by David Leaver,
https://github.com/Leaflet/Leaflet.markercluster, released under the MIT 
Licence, repeated below.

Copyright 2012 David Leaver

MIT LICENCE

Permission is hereby granted, free of charge, to any person obtaining
a copy of this software and associated documentation files (the
"Software"), to deal in the Software without restriction, including
without limitation the rights to use, copy, modify, merge, publish,
distribute, sublicense, and/or sell copies of the Software, and to
permit persons to whom the Software is furnished to do so, subject to
the following conditions:

The above copyright notice and this permission notice shall be
included in all copies or substantial portions of the Software.
*/
!(function (e, t, i) {
  (L.MarkerClusterGroup = L.FeatureGroup.extend({
    options: {
      maxClusterRadius: 80,
      iconCreateFunction: null,
      spiderfyOnMaxZoom: !0,
      showCoverageOnHover: !0,
      zoomToBoundsOnClick: !0,
      singleMarkerMode: !1,
      disableClusteringAtZoom: null,
      removeOutsideVisibleBounds: !0,
      animate: !0,
      animateAddingMarkers: !1,
      spiderfyDistanceMultiplier: 1,
      spiderLegPolylineOptions: {
        weight: 1.5,
        color: "#222",
        opacity: 0.5,
      },
      chunkedLoading: !1,
      chunkInterval: 200,
      chunkDelay: 50,
      chunkProgress: null,
      polygonOptions: {},
      forceMobileMode: !1,
      regionLevels: [0, 0, 1, 1, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4],
      addRegionToolTips: !1,
      toolTipStyle: "auto",
      hullHugFactor: -1,
      clusterAggregationFunction: "sum",
      clusterAggregateRanges: null,
      clusterAggregatePrecision: null,
      clusterAggregatePrefix: "",
      clusterAggregateSuffix: "",
    },
    aggregationFunctions: {
      average(e) {
        for (var t = 0, i = 0, n = 0; n < e.length; n++)
          isNaN(e[n].options.aggregationValue) ||
            ((t += e[n].options.aggregationValue), i++);
        return 0 === i ? 0 : t / i;
      },
      maximum(e) {
        for (var t = e[0].options.aggregationValue, i = 1; i < e.length; i++)
          (isNaN(t) || e[i].options.aggregationValue > t) &&
            (t = e[i].options.aggregationValue);
        return t;
      },
      minimum(e) {
        for (var t = e[0].options.aggregationValue, i = 1; i < e.length; i++)
          (isNaN(t) || e[i].options.aggregationValue < t) &&
            (t = e[i].options.aggregationValue);
        return t;
      },
      sum(e) {
        for (var t = 0, i = 0, n = 0; n < e.length; n++)
          isNaN(e[n].options.aggregationValue) ||
            (t += e[n].options.aggregationValue),
            isNaN(e[n].options.aggregationRefValue) ||
              (i += e[n].options.aggregationRefValue);
        return 0 === i ? t : 100 * (t / i);
      },
    },
    initialize(e) {
      L.Util.setOptions(this, e),
        this.options.iconCreateFunction ||
          (this.options.iconCreateFunction =
            this.options.clusterAggregateRanges &&
            !this.options.singleMarkerMode
              ? this._aggregationIconCreateFunction
              : this._defaultIconCreateFunction),
        (this._featureGroup = L.featureGroup()),
        this._featureGroup.addEventParent(this),
        (this._nonPointGroup = L.featureGroup()),
        this._nonPointGroup.addEventParent(this),
        (this._inZoomAnimation = 0),
        (this._needsClustering = []),
        (this._needsRemoving = []),
        (this._currentShownBounds = null),
        (this._queue = []);
      let t = L.DomUtil.TRANSITION && this.options.animate;
      L.extend(this, t ? this._withAnimation : this._noAnimation),
        (this._markerCluster = t
          ? L.MarkerCluster
          : L.MarkerClusterNonAnimated);
    },
    addLayer(e) {
      if (e instanceof L.LayerGroup) return this.addLayers([e]);
      if (!e.getLatLng) return this._nonPointGroup.addLayer(e), this;
      if (!this._map) return this._needsClustering.push(e), this;
      if (this.hasLayer(e)) return this;
      this._unspiderfy && this._unspiderfy(),
        this._addLayer(e, this._maxZoom),
        this._topClusterLevel._recalculateBounds(),
        this._refreshClustersIcons();
      let t = e,
        i = this._zoom;
      if (e.__parent) for (; t.__parent._zoom >= i; ) t = t.__parent;
      return (
        this._currentShownBounds.contains(t.getLatLng()) &&
          (this.options.animateAddingMarkers
            ? this._animationAddLayer(e, t)
            : this._animationAddLayerNonAnimated(e, t)),
        this
      );
    },
    removeLayer(e) {
      return e instanceof L.LayerGroup
        ? this.removeLayers([e])
        : e.getLatLng
          ? this._map
            ? e.__parent
              ? (this._unspiderfy &&
                  (this._unspiderfy(), this._unspiderfyLayer(e)),
                this._removeLayer(e, !0),
                this._topClusterLevel._recalculateBounds(),
                this._refreshClustersIcons(),
                e.off("move", this._childMarkerMoved, this),
                this._featureGroup.hasLayer(e) &&
                  (this._featureGroup.removeLayer(e),
                  e.clusterShow && e.clusterShow()),
                this)
              : this
            : (!this._arraySplice(this._needsClustering, e) &&
                this.hasLayer(e) &&
                this._needsRemoving.push(e),
              this)
          : (this._nonPointGroup.removeLayer(e), this);
    },
    addLayers(e) {
      if (!L.Util.isArray(e)) return this.addLayer(e);
      let t,
        i = this._featureGroup,
        n = this._nonPointGroup,
        s = this.options.chunkedLoading,
        r = this.options.chunkInterval,
        o = this.options.chunkProgress,
        a = e.length,
        l = 0,
        h = !0;
      if (this._map) {
        var u = new Date().getTime(),
          _ = L.bind(function () {
            for (let d = new Date().getTime(); a > l; l++) {
              if (s && 0 === l % 200) {
                let c = new Date().getTime() - d;
                if (c > r) break;
              }
              if (((t = e[l]), t instanceof L.LayerGroup))
                h && ((e = e.slice()), (h = !1)),
                  this._extractNonGroupLayers(t, e),
                  (a = e.length);
              else if (t.getLatLng) {
                if (
                  !this.hasLayer(t) &&
                  (this._addLayer(t, this._maxZoom),
                  t.__parent && 2 === t.__parent.getChildCount())
                ) {
                  let p = t.__parent.getAllChildMarkers(),
                    g = p[0] === t ? p[1] : p[0];
                  i.removeLayer(g);
                }
              } else n.addLayer(t);
            }
            this.options.addRegionToolTips &&
              this.options.regionLevels.length > 0 &&
              this._addRegionToolTips(this.options.addRegionToolTips),
              o && o(l, a, new Date().getTime() - u),
              l === a
                ? (this._topClusterLevel._recalculateBounds(),
                  this._refreshClustersIcons(),
                  this._topClusterLevel._recursivelyAddChildrenToMap(
                    null,
                    this._zoom,
                    this._currentShownBounds,
                  ))
                : setTimeout(_, this.options.chunkDelay);
          }, this);
        _();
      } else
        for (let d = this._needsClustering; a > l; l++)
          (t = e[l]),
            t instanceof L.LayerGroup
              ? (h && ((e = e.slice()), (h = !1)),
                this._extractNonGroupLayers(t, e),
                (a = e.length))
              : t.getLatLng
                ? this.hasLayer(t) || d.push(t)
                : n.addLayer(t);
      return this;
    },
    removeLayers(e) {
      let t,
        i,
        n = e.length,
        s = this._featureGroup,
        r = this._nonPointGroup,
        o = !0;
      if (!this._map) {
        for (t = 0; n > t; t++)
          (i = e[t]),
            i instanceof L.LayerGroup
              ? (o && ((e = e.slice()), (o = !1)),
                this._extractNonGroupLayers(i, e),
                (n = e.length))
              : (this._arraySplice(this._needsClustering, i),
                r.removeLayer(i),
                this.hasLayer(i) && this._needsRemoving.push(i));
        return this;
      }
      if (this._unspiderfy) {
        this._unspiderfy();
        let a = e.slice(),
          l = n;
        for (t = 0; l > t; t++)
          (i = a[t]),
            i instanceof L.LayerGroup
              ? (this._extractNonGroupLayers(i, a), (l = a.length))
              : this._unspiderfyLayer(i);
      }
      for (t = 0; n > t; t++)
        (i = e[t]),
          i instanceof L.LayerGroup
            ? (o && ((e = e.slice()), (o = !1)),
              this._extractNonGroupLayers(i, e),
              (n = e.length))
            : i.__parent
              ? (this._removeLayer(i, !0, !0),
                s.hasLayer(i) &&
                  (s.removeLayer(i), i.clusterShow && i.clusterShow()))
              : r.removeLayer(i);
      return (
        this._topClusterLevel._recalculateBounds(),
        this._refreshClustersIcons(),
        this._topClusterLevel._recursivelyAddChildrenToMap(
          null,
          this._zoom,
          this._currentShownBounds,
        ),
        this
      );
    },
    clearLayers() {
      return (
        this._map ||
          ((this._needsClustering = []),
          delete this._gridClusters,
          delete this._gridUnclustered),
        this._noanimationUnspiderfy && this._noanimationUnspiderfy(),
        this._featureGroup.clearLayers(),
        this._nonPointGroup.clearLayers(),
        this.eachLayer(function (e) {
          e.off("move", this._childMarkerMoved, this), delete e.__parent;
        }, this),
        this._map && this._generateInitialClusters(),
        this
      );
    },
    getBounds() {
      let e = new L.LatLngBounds();
      this._topClusterLevel && e.extend(this._topClusterLevel._bounds);
      for (let t = this._needsClustering.length - 1; t >= 0; t--)
        e.extend(this._needsClustering[t].getLatLng());
      return e.extend(this._nonPointGroup.getBounds()), e;
    },
    eachLayer(e, t) {
      let i,
        n = this._needsClustering.slice(),
        s = this._needsRemoving;
      for (
        this._topClusterLevel && this._topClusterLevel.getAllChildMarkers(n),
          i = n.length - 1;
        i >= 0;
        i--
      )
        -1 === s.indexOf(n[i]) && e.call(t, n[i]);
      this._nonPointGroup.eachLayer(e, t);
    },
    getLayers() {
      let e = [];
      return (
        this.eachLayer(function (t) {
          e.push(t);
        }),
        e
      );
    },
    getLayer(e) {
      let t = null;
      return (
        (e = parseInt(e, 10)),
        this.eachLayer(function (i) {
          L.stamp(i) === e && (t = i);
        }),
        t
      );
    },
    hasLayer(e) {
      if (!e) return !1;
      let t,
        i = this._needsClustering;
      for (t = i.length - 1; t >= 0; t--) if (i[t] === e) return !0;
      for (i = this._needsRemoving, t = i.length - 1; t >= 0; t--)
        if (i[t] === e) return !1;
      return (
        !(!e.__parent || e.__parent._group !== this) ||
        this._nonPointGroup.hasLayer(e)
      );
    },
    zoomToShowLayer(e, t) {
      "function" != typeof t && (t = function () {});
      var i = function () {
        (!e._icon && !e.__parent._icon) ||
          this._inZoomAnimation ||
          (this._map.off("moveend", i, this),
          this.off("animationend", i, this),
          e._icon
            ? t()
            : e.__parent._icon &&
              (this.once("spiderfied", t, this), e.__parent.spiderfy()));
      };
      if (e._icon && this._map.getBounds().contains(e.getLatLng())) t();
      else if (e.__parent._zoom < Math.round(this._map._zoom))
        this._map.on("moveend", i, this), this._map.panTo(e.getLatLng());
      else {
        var n = function () {
          this._map.off("movestart", n, this), (n = null);
        };
        this._map.on("movestart", n, this),
          this._map.on("moveend", i, this),
          this.on("animationend", i, this),
          e.__parent.zoomToBounds(),
          n && i.call(this);
      }
    },
    onAdd(e) {
      this._map = e;
      let t, i, n;
      if (!isFinite(this._map.getMaxZoom()))
        throw "Map has no maxZoom specified";
      for (
        this._featureGroup.addTo(e),
          this._nonPointGroup.addTo(e),
          this._gridClusters || this._generateInitialClusters(),
          this._maxLat = e.options.crs.projection.MAX_LATITUDE,
          t = 0,
          i = this._needsRemoving.length;
        i > t;
        t++
      )
        (n = this._needsRemoving[t]), this._removeLayer(n, !0);
      (this._needsRemoving = []),
        (this._zoom = Math.round(this._map._zoom)),
        (this._currentShownBounds = this._getExpandedVisibleBounds()),
        this._map.on("zoomend", this._zoomEnd, this),
        this._map.on("moveend", this._moveEnd, this),
        this._spiderfierOnAdd && this._spiderfierOnAdd(),
        this._bindEvents(),
        (i = this._needsClustering),
        (this._needsClustering = []),
        this.addLayers(i);
    },
    onRemove(e) {
      e.off("zoomend", this._zoomEnd, this),
        e.off("moveend", this._moveEnd, this),
        this._unbindEvents(),
        (this._map._mapPane.className = this._map._mapPane.className.replace(
          " leaflet-cluster-anim",
          "",
        )),
        this._spiderfierOnRemove && this._spiderfierOnRemove(),
        delete this._maxLat,
        this._hideCoverage(),
        this._featureGroup.remove(),
        this._nonPointGroup.remove(),
        this._featureGroup.clearLayers(),
        (this._map = null);
    },
    getVisibleParent(e) {
      for (var t = e; t && !t._icon; ) t = t.__parent;
      return t || null;
    },
    getRegionLevel(e) {
      let t = Math.min(e, this.options.regionLevels.length - 1);
      return this.options.regionLevels[t];
    },
    _arraySplice(e, t) {
      for (let i = e.length - 1; i >= 0; i--)
        if (e[i] === t) return e.splice(i, 1), !0;
    },
    _removeFromGridUnclustered(e, t) {
      for (
        let i = this._map, n = this._gridUnclustered;
        t >= 0 && n[t].removeObject(e, i.project(e.getLatLng(), t));
        t--
      );
    },
    _childMarkerMoved(e) {
      this._ignoreMove ||
        ((e.target._latlng = e.oldLatLng),
        this.removeLayer(e.target),
        (e.target._latlng = e.latlng),
        this.addLayer(e.target));
    },
    _removeLayer(e, t, i) {
      let n = this._gridClusters,
        s = this._gridUnclustered,
        r = this._featureGroup,
        o = this._map;
      t && this._removeFromGridUnclustered(e, this._maxZoom);
      let a,
        l = e.__parent,
        h = l._markers;
      for (
        this._arraySplice(h, e);
        l && (l._childCount--, (l._boundsNeedUpdate = !0), !(l._zoom < 0));

      )
        t && l._childCount <= 1
          ? ((a = l._markers[0] === e ? l._markers[1] : l._markers[0]),
            n[l._zoom].removeObject(l, o.project(l._cLatLng, l._zoom)),
            a && s[l._zoom].addObject(a, o.project(a.getLatLng(), l._zoom)),
            this._arraySplice(l.__parent._childClusters, l),
            a && (l.__parent._markers.push(a), (a.__parent = l.__parent)),
            l._icon && (r.removeLayer(l), !i && a && r.addLayer(a)))
          : (l._iconNeedsUpdate = !0),
          (l = l.__parent);
      delete e.__parent;
    },
    _isOrIsParent(e, t) {
      for (; t; ) {
        if (e === t) return !0;
        t = t.parentNode;
      }
      return !1;
    },
    fire(e, t, i) {
      if (t && t.layer instanceof L.MarkerCluster) {
        if (
          t.originalEvent &&
          this._isOrIsParent(t.layer._icon, t.originalEvent.relatedTarget)
        )
          return;
        if (L.Browser.mobile || this.options.forceMobileMode) {
          if ("click" === e && "mouseover" === t.layer.lastClusterEvent)
            return (t.layer.lastClusterEvent = null), void 0;
          t.layer.lastClusterEvent = e;
        }
        e = `cluster${e}`;
      }
      L.FeatureGroup.prototype.fire.call(this, e, t, i);
    },
    listens(e, t) {
      return (
        L.FeatureGroup.prototype.listens.call(this, e, t) ||
        L.FeatureGroup.prototype.listens.call(this, `cluster${e}`, t)
      );
    },
    _defaultIconCreateFunction(e) {
      let t = e.getChildCount(),
        i = " marker-cluster-";
      return (
        (i +=
          10 > t ? "small" : 100 > t ? "medium" : 1e3 > t ? "large" : "xlarge"),
        new L.DivIcon({
          html: `<div><span>${t}</span></div>`,
          className: `marker-cluster${i}`,
          iconSize: new L.Point(40, 40),
        })
      );
    },
    _aggregationIconCreateFunction(e) {
      let t,
        i = e.getAllChildMarkers();
      if ("function" == typeof this.clusterAggregationFunction)
        t = this.clusterAggregationFunction(i);
      else {
        if (!e._group) return;
        let n = e._group.aggregationFunctions;
        switch (this.clusterAggregationFunction) {
          case "average":
            t = n.average(i);
            break;
          case "maximum":
            t = n.maximum(i);
            break;
          case "minimum":
            t = n.minimum(i);
            break;
          default:
            t = n.sum(i);
        }
      }
      let s =
          "+" === this.clusterAggregatePrefix
            ? t > 0
              ? "+"
              : ""
            : this.clusterAggregatePrefix,
        r = this.clusterAggregateSuffix,
        o = "xlarge";
      for (let a in this.clusterAggregateRanges)
        if (t < this.clusterAggregateRanges[a]) {
          o = a;
          break;
        }
      return (
        this.clusterAggregatePrecision &&
          (t =
            "round" === this.clusterAggregatePrecision
              ? Math.round(t)
              : t
                  .toPrecision(this.clusterAggregatePrecision)
                  .replace(/\.0+$/, "")),
        ~new L.DivIcon({
          html:
            `<div><span>${i.length}</span>` +
            `<span>${s}${t}${r}</span>` +
            `</div>`,
          className: `marker-cluster-aggregate marker-cluster-${o}`,
          iconSize: new L.Point(50, 50),
        })
      );
    },
    _bindEvents() {
      let e = this._map,
        t = this.options.spiderfyOnMaxZoom,
        i = this.options.showCoverageOnHover,
        n = this.options.zoomToBoundsOnClick;
      (t || n) && this.on("clusterclick", this._zoomOrSpiderfy, this),
        i &&
          (this.on("clustermouseover", this._showCoverage, this),
          this.on("clustermouseout", this._hideCoverage, this),
          e.on("zoomend", this._hideCoverage, this)),
        this.options.addRegionToolTips &&
          this.on("clustermouseover", this._showSubRegionsToolTip, this);
    },
    _zoomOrSpiderfy(e) {
      for (var t = e.layer, i = t; 1 === i._childClusters.length; )
        i = i._childClusters[0];
      i._zoom === this._maxZoom &&
      i._childCount === t._childCount &&
      this.options.spiderfyOnMaxZoom
        ? t.spiderfy()
        : this.options.zoomToBoundsOnClick && t.zoomToBounds(),
        e.originalEvent &&
          13 === e.originalEvent.keyCode &&
          this._map._container.focus();
    },
    _showCoverage(e) {
      let t = this._map;
      this._inZoomAnimation ||
        (this._shownPolygon && t.removeLayer(this._shownPolygon),
        e.layer.getChildCount() > 2 &&
          e.layer !== this._spiderfied &&
          ((this._shownPolygon = new L.Polygon(
            e.layer.getConvexHull(),
            this.options.polygonOptions,
          )),
          t.addLayer(this._shownPolygon)));
    },
    _showSubRegionsToolTip(e) {
      let t = e.layer;
      if (t && t._subRegions) {
        let i = {
          offset: L.point(0, -12),
          maxWidth: 150,
          maxHeight: 150,
        };
        setTimeout(function () {
          // console.log("in settimeout for bindpopup", t);
          t.bindPopup(t._subRegions, i);
          t._popup._getAnchor = () => [0, 0];
          t.openPopup();
        }, 100);
      }
    },
    _hideCoverage() {
      this._shownPolygon &&
        (this._map.removeLayer(this._shownPolygon),
        (this._shownPolygon = null));
    },
    _unbindEvents() {
      let e = this.options.spiderfyOnMaxZoom,
        t = this.options.showCoverageOnHover,
        i = this.options.zoomToBoundsOnClick,
        n = this._map;
      (e || i) && this.off("clusterclick", this._zoomOrSpiderfy, this),
        t &&
          (this.off("clustermouseover", this._showCoverage, this),
          this.off("clustermouseout", this._hideCoverage, this),
          n.off("zoomend", this._hideCoverage, this));
    },
    _zoomEnd() {
      this._map &&
        (this._mergeSplitClusters(),
        (this._zoom = Math.round(this._map._zoom)),
        (this._currentShownBounds = this._getExpandedVisibleBounds()));
    },
    _moveEnd() {
      if (!this._inZoomAnimation) {
        let e = this._getExpandedVisibleBounds();
        this._topClusterLevel._recursivelyRemoveChildrenFromMap(
          this._currentShownBounds,
          this._zoom,
          e,
        ),
          this._topClusterLevel._recursivelyAddChildrenToMap(
            null,
            Math.round(this._map._zoom),
            e,
          ),
          (this._currentShownBounds = e);
      }
    },
    _generateInitialClusters() {
      let e = this._map.getMaxZoom(),
        t = this.options.maxClusterRadius,
        i = t;
      "function" != typeof t &&
        (i = function () {
          return t;
        }),
        this.options.maxClusterRadius
          ? this.options.disableClusteringAtZoom &&
            (e = this.options.disableClusteringAtZoom - 1)
          : (e = -1),
        (this._maxZoom = e),
        (this._gridClusters = {}),
        (this._gridUnclustered = {});
      for (let n = e; n >= 0; n--)
        (this._gridClusters[n] = new L.DistanceGrid(i(n))),
          (this._gridUnclustered[n] = new L.DistanceGrid(i(n)));
      this._topClusterLevel = new this._markerCluster(this, -1);
    },
    _addLayer(e, t) {
      let i,
        n,
        s,
        r = this._gridClusters,
        o = this._gridUnclustered,
        a = this.options.regionLevels;
      for (
        this.options.singleMarkerMode && this._overrideMarkerIcon(e),
          e.on("move", this._childMarkerMoved, this);
        t >= 0;
        t--
      ) {
        (i = this._map.project(e.getLatLng(), t)),
          (i.region = e.getRegion(t, a));
        let l = r[t].getNearObject(i, t, a);
        if (l) return l._addChild(e), (e.__parent = l), void 0;
        if (
          ((l = o[t].getNearObject(i, t, a)),
          l || this.options.allowClustersOfOne)
        ) {
          let h = l ? l.getLatLng() : e.getLatLng();
          if (l) (s = l.__parent), this._removeLayer(l, !1);
          else if (this.options.allowClustersOfOne) {
            if (((s = null), this._topClusterLevel.getChildCount()))
              for (n = t - 1; n >= 0; n--) {
                let u = this._map.project(h, n);
                if (
                  ((u.region = e.getRegion(n, a)),
                  (s = r[n].getNearObject(u, n, a)))
                )
                  break;
              }
            s || (s = this._topClusterLevel);
          }
          let _ = new this._markerCluster(this, t, e, l);
          r[t].addObject(_, this._map.project(_._cLatLng, t)),
            l && (l.__parent = _),
            (e.__parent = _);
          let d = _;
          for (n = t - 1; n > s._zoom; n--)
            (d = new this._markerCluster(this, n, d)),
              r[n].addObject(d, this._map.project(h, n));
          return (
            s._addChild(d), l && this._removeFromGridUnclustered(l, t), void 0
          );
        }
        o[t].addObject(e, i);
      }
      this._topClusterLevel._addChild(e), (e.__parent = this._topClusterLevel);
    },
    _refreshClustersIcons() {
      this._featureGroup.eachLayer(function (e) {
        e instanceof L.MarkerCluster && e._iconNeedsUpdate && e._updateIcon();
      });
    },
    _enqueue(e) {
      this._queue.push(e),
        this._queueTimeout ||
          (this._queueTimeout = setTimeout(
            L.bind(this._processQueue, this),
            300,
          ));
    },
    _processQueue() {
      for (let e = 0; e < this._queue.length; e++) this._queue[e].call(this);
      (this._queue.length = 0),
        clearTimeout(this._queueTimeout),
        (this._queueTimeout = null);
    },
    _mergeSplitClusters() {
      let e = Math.round(this._map._zoom);
      this._processQueue(),
        this._zoom < e &&
        this._currentShownBounds.intersects(this._getExpandedVisibleBounds())
          ? (this._animationStart(),
            this._topClusterLevel._recursivelyRemoveChildrenFromMap(
              this._currentShownBounds,
              this._zoom,
              this._getExpandedVisibleBounds(),
            ),
            this._animationZoomIn(this._zoom, e))
          : this._zoom > e
            ? (this._animationStart(), this._animationZoomOut(this._zoom, e))
            : this._moveEnd();
    },
    _getExpandedVisibleBounds() {
      return this.options.removeOutsideVisibleBounds
        ? L.Browser.mobile
          ? this._checkBoundsMaxLat(this._map.getBounds())
          : this._checkBoundsMaxLat(this._map.getBounds().pad(1))
        : this._mapBoundsInfinite;
    },
    _checkBoundsMaxLat(e) {
      let t = this._maxLat;
      return (
        t !== i &&
          (e.getNorth() >= t && (e._northEast.lat = 1 / 0),
          e.getSouth() <= -t && (e._southWest.lat = -1 / 0)),
        e
      );
    },
    _animationAddLayerNonAnimated(e, t) {
      if (t === e) this._featureGroup.addLayer(e);
      else if (t._childCount <= 2) {
        t._addToMap();
        let i = t.getAllChildMarkers();
        this._featureGroup.removeLayer(i[0]),
          i.length > 1 && this._featureGroup.removeLayer(i[1]);
      } else t._updateIcon();
    },
    _addRegionToolTips(e) {
      for (
        let t = this.options.forceMobileMode || L.Browser.mobile,
          i = this._maxZoom;
        i >= 0;
        i--
      ) {
        let n = this.getRegionLevel(i) + 1,
          s = this._gridClusters[i]._grid;
        for (let r in s) {
          let o = s[r];
          for (let a in o)
            for (let l = o[a], h = 0; h < l.length; h++) {
              let u = l[h],
                _ = u.getRegion(),
                d = "without subregions" === e ? [] : u.getSubRegions(_, n);
              d = 0 === d.length ? "" : d.sort().join(", ");
              let c = _ && d ? `${_}: ${d}` : _ ? _ : d;
              "balloon" === this.options.toolTipStyle ||
              (t && "auto" === this.options.toolTipStyle)
                ? (u._subRegions = c)
                : (u.options.title = c);
            }
        }
      }
    },
    _extractNonGroupLayers(e, t) {
      let i,
        n = e.getLayers(),
        s = 0;
      for (t = t || []; s < n.length; s++)
        (i = n[s]),
          i instanceof L.LayerGroup
            ? this._extractNonGroupLayers(i, t)
            : t.push(i);
      return t;
    },
    _overrideMarkerIcon(e) {
      let t = (e.options.icon = this.options.iconCreateFunction({
        getChildCount() {
          return 1;
        },
        getAllChildMarkers() {
          return [e];
        },
      }));
      return t;
    },
  })),
    (L.Marker.prototype.getRegion = function (e, t) {
      if (!t || !this.options.regions) return null;
      (e === i || e >= t.length) && (e = t.length - 1);
      let n = Math.min(t[e], this.options.regions.length - 1);
      return this.options.regions[n];
    }),
    L.MarkerClusterGroup.include({
      _mapBoundsInfinite: new L.LatLngBounds(
        new L.LatLng(-1 / 0, -1 / 0),
        new L.LatLng(1 / 0, 1 / 0),
      ),
    }),
    L.MarkerClusterGroup.include({
      _noAnimation: {
        _animationStart() {},
        _animationZoomIn(e, t) {
          this._topClusterLevel._recursivelyRemoveChildrenFromMap(
            this._currentShownBounds,
            e,
          ),
            this._topClusterLevel._recursivelyAddChildrenToMap(
              null,
              t,
              this._getExpandedVisibleBounds(),
            ),
            this.fire("animationend");
        },
        _animationZoomOut(e, t) {
          this._topClusterLevel._recursivelyRemoveChildrenFromMap(
            this._currentShownBounds,
            e,
          ),
            this._topClusterLevel._recursivelyAddChildrenToMap(
              null,
              t,
              this._getExpandedVisibleBounds(),
            ),
            this.fire("animationend");
        },
        _animationAddLayer(e, t) {
          this._animationAddLayerNonAnimated(e, t);
        },
      },
      _withAnimation: {
        _animationStart() {
          (this._map._mapPane.className += " leaflet-cluster-anim"),
            this._inZoomAnimation++;
        },
        _animationZoomIn(e, t) {
          let i,
            n = this._getExpandedVisibleBounds(),
            s = this._featureGroup;
          (this._ignoreMove = !0),
            this._topClusterLevel._recursively(n, e, 0, function (r) {
              let o,
                a = r._latlng,
                l = r._markers;
              for (
                n.contains(a) || (a = null),
                  r._isSingleParent() && e + 1 === t
                    ? (s.removeLayer(r),
                      r._recursivelyAddChildrenToMap(null, t, n))
                    : (r.clusterHide(),
                      r._recursivelyAddChildrenToMap(a, t, n)),
                  i = l.length - 1;
                i >= 0;
                i--
              )
                (o = l[i]), n.contains(o._latlng) || s.removeLayer(o);
            }),
            this._forceLayout(),
            this._topClusterLevel._recursivelyBecomeVisible(n, t),
            s.eachLayer(function (e) {
              e instanceof L.MarkerCluster || !e._icon || e.clusterShow();
            }),
            this._topClusterLevel._recursively(n, e, t, function (e) {
              e._recursivelyRestoreChildPositions(t);
            }),
            (this._ignoreMove = !1),
            this._enqueue(function () {
              this._topClusterLevel._recursively(n, e, 0, function (e) {
                s.removeLayer(e), e.clusterShow();
              }),
                this._animationEnd();
            });
        },
        _animationZoomOut(e, t) {
          this._animationZoomOutSingle(this._topClusterLevel, e - 1, t),
            this._topClusterLevel._recursivelyAddChildrenToMap(
              null,
              t,
              this._getExpandedVisibleBounds(),
            ),
            this._topClusterLevel._recursivelyRemoveChildrenFromMap(
              this._currentShownBounds,
              e,
              this._getExpandedVisibleBounds(),
            );
        },
        _animationAddLayer(e, t) {
          let i = this,
            n = this._featureGroup;
          n.addLayer(e),
            t !== e &&
              (t._childCount > 2
                ? (t._updateIcon(),
                  this._forceLayout(),
                  this._animationStart(),
                  e._setPos(this._map.latLngToLayerPoint(t.getLatLng())),
                  e.clusterHide(),
                  this._enqueue(function () {
                    n.removeLayer(e), e.clusterShow(), i._animationEnd();
                  }))
                : (this._forceLayout(),
                  i._animationStart(),
                  i._animationZoomOutSingle(
                    t,
                    this._map.getMaxZoom(),
                    this._zoom,
                  )));
        },
      },
      _animationZoomOutSingle(e, t, i) {
        let n = this._getExpandedVisibleBounds();
        e._recursivelyAnimateChildrenInAndAddSelfToMap(n, t + 1, i);
        let s = this;
        this._forceLayout(),
          e._recursivelyBecomeVisible(n, i),
          this._enqueue(function () {
            if (1 === e._childCount) {
              let r = e._markers[0];
              r &&
                ((this._ignoreMove = !0),
                r.setLatLng(r.getLatLng()),
                (this._ignoreMove = !1),
                r.clusterShow && r.clusterShow());
            } else
              e._recursively(n, i, 0, function (e) {
                e._recursivelyRemoveChildrenFromMap(n, t + 1);
              });
            s._animationEnd();
          });
      },
      _animationEnd() {
        this._map &&
          (this._map._mapPane.className = this._map._mapPane.className.replace(
            " leaflet-cluster-anim",
            "",
          )),
          this._inZoomAnimation--,
          this.fire("animationend");
      },
      _forceLayout() {
        L.Util.falseFn(t.body.offsetWidth);
      },
    }),
    (L.markerClusterGroup = function (e) {
      return new L.MarkerClusterGroup(e);
    }),
    (L.MarkerCluster = L.Marker.extend({
      initialize(e, t, i, n) {
        L.Marker.prototype.initialize.call(
          this,
          i ? i._cLatLng || i.getLatLng() : new L.LatLng(0, 0),
          {
            icon: this,
          },
        ),
          (this._group = e),
          (this._zoom = t),
          (this._markers = []),
          (this._childClusters = []),
          (this._childCount = 0),
          (this._iconNeedsUpdate = !0),
          (this._boundsNeedUpdate = !0),
          (this._bounds = new L.LatLngBounds()),
          i && this._addChild(i),
          n && this._addChild(n);
      },
      getAllChildMarkers(e) {
        e = e || [];
        for (let t = this._childClusters.length - 1; t >= 0; t--)
          this._childClusters[t].getAllChildMarkers(e);
        for (let i = this._markers.length - 1; i >= 0; i--)
          e.push(this._markers[i]);
        return e;
      },
      getChildCount() {
        return this._childCount;
      },
      zoomToBounds() {
        for (
          var e,
            t = this._childClusters.slice(),
            i = this._group._map,
            n = i.getBoundsZoom(this._bounds),
            s = this._zoom + 1,
            r = i.getZoom();
          t.length > 0 && n > s;

        ) {
          s++;
          let o = [];
          for (e = 0; e < t.length; e++) o = o.concat(t[e]._childClusters);
          t = o;
        }
        n > s
          ? this._group._map.setView(this._latlng, s)
          : r >= n
            ? this._group._map.setView(this._latlng, r + 1)
            : this._group._map.fitBounds(this._bounds);
      },
      getBounds() {
        let e = new L.LatLngBounds();
        return e.extend(this._bounds), e;
      },
      getRegion(e) {
        e = e === i ? this._zoom : e;
        let t = "",
          n = 0,
          s = this._markers;
        if (0 === s.length)
          for (; n < this._childClusters.length && !t; n++)
            t = this._childClusters[n].getRegion(e);
        else
          for (; n < s.length && !t; n++)
            t = s[n].getRegion(e, this._group.options.regionLevels);
        return t;
      },
      getSubRegions(e, t) {
        for (
          var i = [],
            n = this._group,
            s = this.getAllChildMarkers(),
            r = this._zoom + 1;
          r <= n._maxZoom && !(n.getRegionLevel(r) > t);
          r++
        )
          for (let o = s.length - 1; o >= 0; o--) {
            let a = s[o].getRegion(r, n.options.regionLevels);
            a && a !== e && i.indexOf(a) < 0 && i.push(a);
          }
        return i;
      },
      _updateIcon() {
        (this._iconNeedsUpdate = !0), this._icon && this.setIcon(this);
      },
      createIcon() {
        return (
          this._iconNeedsUpdate &&
            ((this._iconObj = this._group.options.iconCreateFunction(this)),
            (this._iconNeedsUpdate = !1)),
          this._iconObj.createIcon()
        );
      },
      createShadow() {
        return this._iconObj.createShadow();
      },
      _addChild(e, t) {
        (this._iconNeedsUpdate = !0),
          (this._boundsNeedUpdate = !0),
          this._setClusterCenter(e),
          e instanceof L.MarkerCluster
            ? (t || (this._childClusters.push(e), (e.__parent = this)),
              (this._childCount += e._childCount))
            : (t || this._markers.push(e), this._childCount++),
          this.__parent && this.__parent._addChild(e, !0);
      },
      _setClusterCenter(e) {
        this._cLatLng || (this._cLatLng = e._cLatLng || e._latlng);
      },
      _resetBounds() {
        let e = this._bounds;
        e._southWest &&
          ((e._southWest.lat = 1 / 0), (e._southWest.lng = 1 / 0)),
          e._northEast &&
            ((e._northEast.lat = -1 / 0), (e._northEast.lng = -1 / 0));
      },
      _recalculateBounds() {
        let e,
          t,
          i,
          n,
          s = this._markers,
          r = this._childClusters,
          o = 0,
          a = 0,
          l = this._childCount;
        if (0 !== l) {
          for (this._resetBounds(), e = 0; e < s.length; e++)
            (i = s[e]._latlng),
              this._bounds.extend(i),
              (o += i.lat),
              (a += i.lng);
          for (e = 0; e < r.length; e++)
            (t = r[e]),
              t._boundsNeedUpdate && t._recalculateBounds(),
              this._bounds.extend(t._bounds),
              (i = t._wLatLng),
              (n = t._childCount),
              (o += i.lat * n),
              (a += i.lng * n);
          (this._latlng = this._wLatLng = new L.LatLng(o / l, a / l)),
            (this._boundsNeedUpdate = !1);
        }
      },
      _addToMap(e) {
        e && ((this._backupLatlng = this._latlng), this.setLatLng(e)),
          this._group._featureGroup.addLayer(this);
      },
      _recursivelyAnimateChildrenIn(e, t, i) {
        this._recursively(
          e,
          0,
          i - 1,
          function (e) {
            let i,
              n,
              s = e._markers;
            for (i = s.length - 1; i >= 0; i--)
              (n = s[i]), n._icon && (n._setPos(t), n.clusterHide());
          },
          function (e) {
            let i,
              n,
              s = e._childClusters;
            for (i = s.length - 1; i >= 0; i--)
              (n = s[i]), n._icon && (n._setPos(t), n.clusterHide());
          },
        );
      },
      _recursivelyAnimateChildrenInAndAddSelfToMap(e, t, i) {
        this._recursively(e, i, 0, function (n) {
          n._recursivelyAnimateChildrenIn(
            e,
            n._group._map.latLngToLayerPoint(n.getLatLng()).round(),
            t,
          ),
            n._isSingleParent() && t - 1 === i
              ? (n.clusterShow(), n._recursivelyRemoveChildrenFromMap(e, t))
              : n.clusterHide(),
            n._addToMap();
        });
      },
      _recursivelyBecomeVisible(e, t) {
        this._recursively(e, 0, t, null, function (e) {
          e.clusterShow();
        });
      },
      _recursivelyAddChildrenToMap(e, t, i) {
        this._recursively(
          i,
          -1,
          t,
          function (n) {
            if (t !== n._zoom)
              for (let s = n._markers.length - 1; s >= 0; s--) {
                let r = n._markers[s];
                i.contains(r._latlng) &&
                  (e &&
                    ((r._backupLatlng = r.getLatLng()),
                    r.setLatLng(e),
                    r.clusterHide && r.clusterHide()),
                  n._group._featureGroup.addLayer(r));
              }
          },
          function (t) {
            t._addToMap(e);
          },
        );
      },
      _recursivelyRestoreChildPositions(e) {
        for (let t = this._markers.length - 1; t >= 0; t--) {
          let i = this._markers[t];
          i._backupLatlng &&
            (i.setLatLng(i._backupLatlng), delete i._backupLatlng);
        }
        if (e - 1 === this._zoom)
          for (let n = this._childClusters.length - 1; n >= 0; n--)
            this._childClusters[n]._restorePosition();
        else
          for (let s = this._childClusters.length - 1; s >= 0; s--)
            this._childClusters[s]._recursivelyRestoreChildPositions(e);
      },
      _restorePosition() {
        this._backupLatlng &&
          (this.setLatLng(this._backupLatlng), delete this._backupLatlng);
      },
      _recursivelyRemoveChildrenFromMap(e, t, i) {
        let n, s;
        this._recursively(
          e,
          -1,
          t - 1,
          function (e) {
            for (s = e._markers.length - 1; s >= 0; s--)
              (n = e._markers[s]),
                (i && i.contains(n._latlng)) ||
                  (e._group._featureGroup.removeLayer(n),
                  n.clusterShow && n.clusterShow());
          },
          function (e) {
            for (s = e._childClusters.length - 1; s >= 0; s--)
              (n = e._childClusters[s]),
                (i && i.contains(n._latlng)) ||
                  (e._group._featureGroup.removeLayer(n),
                  n.clusterShow && n.clusterShow());
          },
        );
      },
      _recursively(e, t, i, n, s) {
        let r,
          o,
          a = this._childClusters,
          l = this._zoom;
        if ((l >= t && (n && n(this), s && l === i && s(this)), t > l || i > l))
          for (r = a.length - 1; r >= 0; r--)
            (o = a[r]),
              e.intersects(o._bounds) && o._recursively(e, t, i, n, s);
      },
      _isSingleParent() {
        return (
          this._childClusters.length > 0 &&
          this._childClusters[0]._childCount === this._childCount
        );
      },
    })),
    L.Marker.include({
      clusterHide() {
        return (
          (this.options.opacityWhenUnclustered = this.options.opacity || 1),
          this.setOpacity(0)
        );
      },
      clusterShow() {
        let e = this.setOpacity(
          this.options.opacity || this.options.opacityWhenUnclustered,
        );
        return delete this.options.opacityWhenUnclustered, e;
      },
    }),
    (L.DistanceGrid = function (e) {
      (this._cellSize = e),
        (this._sqCellSize = e * e),
        (this._grid = {}),
        (this._objectPoint = {});
    }),
    (L.DistanceGrid.prototype = {
      addObject(e, t) {
        let i = this._getCoord(t.x),
          n = this._getCoord(t.y),
          s = this._grid,
          r = (s[n] = s[n] || {}),
          o = (r[i] = r[i] || []),
          a = L.Util.stamp(e);
        (this._objectPoint[a] = t), o.push(e);
      },
      updateObject(e, t) {
        this.removeObject(e), this.addObject(e, t);
      },
      removeObject(e, t) {
        let i,
          n,
          s = this._getCoord(t.x),
          r = this._getCoord(t.y),
          o = this._grid,
          a = (o[r] = o[r] || {}),
          l = (a[s] = a[s] || []);
        for (
          delete this._objectPoint[L.Util.stamp(e)], i = 0, n = l.length;
          n > i;
          i++
        )
          if (l[i] === e) return l.splice(i, 1), 1 === n && delete a[s], !0;
      },
      eachObject(e, t) {
        let i,
          n,
          s,
          r,
          o,
          a,
          l,
          h = this._grid;
        for (i in h) {
          o = h[i];
          for (n in o)
            for (a = o[n], s = 0, r = a.length; r > s; s++)
              (l = e.call(t, a[s])), l && (s--, r--);
        }
      },
      getNearObject(e, t, i) {
        let n,
          s,
          r,
          o,
          a,
          l,
          h,
          u,
          _ = this._getCoord(e.x),
          d = this._getCoord(e.y),
          c = this._objectPoint,
          p = this._sqCellSize,
          g = null;
        for (n = d - 1; d + 1 >= n; n++)
          if ((o = this._grid[n]))
            for (s = _ - 1; _ + 1 >= s; s++)
              if ((a = o[s]))
                for (r = 0, l = a.length; l > r; r++) {
                  h = a[r];
                  let f = c[L.Util.stamp(h)];
                  f || console.log("DistanceGrid: no obj point.");
                  let m = !f || (e.region && e.region !== h.getRegion(t, i));
                  m || ((u = this._sqDist(f, e)), p > u && ((p = u), (g = h)));
                }
        return g;
      },
      _getCoord(e) {
        return Math.floor(e / this._cellSize);
      },
      _sqDist(e, t) {
        let i = t.x - e.x,
          n = t.y - e.y;
        return i * i + n * n;
      },
    }),
    (function () {
      L.QuickHull = {
        getDistant(e, t) {
          let i = t[1].lat - t[0].lat,
            n = t[0].lng - t[1].lng;
          return n * (e.lat - t[0].lat) + i * (e.lng - t[0].lng);
        },
        findMostDistantPointFromBaseLine(e, t) {
          let i,
            n,
            s,
            r = 0,
            o = null,
            a = [];
          for (i = t.length - 1; i >= 0; i--)
            (n = t[i]),
              (s = this.getDistant(n, e)),
              s > 0 && (a.push(n), s > r && ((r = s), (o = n)));
          return {
            maxPoint: o,
            newPoints: a,
          };
        },
        buildConvexHull(e, t) {
          let i = [],
            n = this.findMostDistantPointFromBaseLine(e, t);
          return n.maxPoint
            ? ((i = i.concat(
                this.buildConvexHull([e[0], n.maxPoint], n.newPoints),
              )),
              (i = i.concat(
                this.buildConvexHull([n.maxPoint, e[1]], n.newPoints),
              )))
            : [e[0]];
        },
        getConvexHull(e) {
          let t,
            i = !1,
            n = !1,
            s = !1,
            r = !1,
            o = null,
            a = null,
            l = null,
            h = null,
            u = null,
            _ = null;
          for (t = e.length - 1; t >= 0; t--) {
            let d = e[t];
            (i === !1 || d.lat > i) && ((o = d), (i = d.lat)),
              (n === !1 || d.lat < n) && ((a = d), (n = d.lat)),
              (s === !1 || d.lng > s) && ((l = d), (s = d.lng)),
              (r === !1 || d.lng < r) && ((h = d), (r = d.lng));
          }
          n !== i ? ((_ = a), (u = o)) : ((_ = h), (u = l));
          let c = [].concat(
            this.buildConvexHull([_, u], e),
            this.buildConvexHull([u, _], e),
          );
          return c;
        },
      };
    })(),
    (function () {
      L.HeuristicHull = {
        calculateHull(e, t) {
          let i = e.length;
          if (4 >= i && 2 !== i) return L.QuickHull.getConvexHull(e);
          let n = e.concat();
          n.sort(function (e, t) {
            return e.lat < t.lat ? -1 : e.lat > t.lat ? 1 : 0;
          });
          let s;
          s = isNaN(t)
            ? i > 200
              ? 0.0667
              : i > 100
                ? 0.126
                : i > 50
                  ? 0.251
                  : i > 20
                    ? 0.334
                    : i > 8
                      ? 0.51
                      : 1
            : 1 - Math.max(0, Math.min(t, 1));
          for (
            var r = i - 1,
              o = s * (n[r - 1].lat - n[1].lat),
              a = [n[0]],
              l = [],
              h = 1;
            r > h;

          ) {
            for (var u = h, _ = h, d = n[h++].lat + o; r > h && n[h].lat <= d; )
              n[h].lng <= n[u].lng ? (u = h) : n[h].lng > n[_].lng && (_ = h),
                h++;
            a.push(n[u]), l.unshift(n[_]);
          }
          a.push(n[r]);
          let c = a.concat(l);
          return c;
        },
      };
    })(),
    L.MarkerCluster.include({
      getConvexHull() {
        let e,
          t,
          i = this.getAllChildMarkers(),
          n = [];
        for (t = i.length - 1; t >= 0; t--) (e = i[t].getLatLng()), n.push(e);
        let s = this._group.options.hullHugFactor;
        return 0 > s
          ? L.QuickHull.getConvexHull(n)
          : L.HeuristicHull.calculateHull(n, s);
      },
    }),
    L.MarkerCluster.include({
      _2PI: 2 * Math.PI,
      _circleFootSeparation: 25,
      _circleStartAngle: Math.PI / 6,
      _spiralFootSeparation: 28,
      _spiralLengthStart: 11,
      _spiralLengthFactor: 5,
      _circleSpiralSwitchover: 9,
      spiderfy() {
        if (this._group._spiderfied !== this && !this._group._inZoomAnimation) {
          let e,
            t = this.getAllChildMarkers(),
            i = this._group,
            n = i._map,
            s = n.latLngToLayerPoint(this._latlng);
          this._group._unspiderfy(),
            (this._group._spiderfied = this),
            t.length >= this._circleSpiralSwitchover
              ? (e = this._generatePointsSpiral(t.length, s))
              : ((s.y += 10), (e = this._generatePointsCircle(t.length, s))),
            this._animationSpiderfy(t, e);
        }
      },
      unspiderfy(e) {
        this._group._inZoomAnimation ||
          (this._animationUnspiderfy(e), (this._group._spiderfied = null));
      },
      _generatePointsCircle(e, t) {
        let i,
          n,
          s =
            this._group.options.spiderfyDistanceMultiplier *
            this._circleFootSeparation *
            (2 + e),
          r = s / this._2PI,
          o = this._2PI / e,
          a = [];
        for (a.length = e, i = e - 1; i >= 0; i--)
          (n = this._circleStartAngle + i * o),
            (a[i] = new L.Point(
              t.x + r * Math.cos(n),
              t.y + r * Math.sin(n),
            )._round());
        return a;
      },
      _generatePointsSpiral(e, t) {
        let i,
          n = this._group.options.spiderfyDistanceMultiplier,
          s = n * this._spiralLengthStart,
          r = n * this._spiralFootSeparation,
          o = n * this._spiralLengthFactor * this._2PI,
          a = 0,
          l = [];
        for (l.length = e, i = e - 1; i >= 0; i--)
          (a += r / s + 5e-4 * i),
            (l[i] = new L.Point(
              t.x + s * Math.cos(a),
              t.y + s * Math.sin(a),
            )._round()),
            (s += o / a);
        return l;
      },
      _noanimationUnspiderfy() {
        let e,
          t,
          i = this._group,
          n = i._map,
          s = i._featureGroup,
          r = this.getAllChildMarkers();
        for (
          i._ignoreMove = !0, this.setOpacity(1), t = r.length - 1;
          t >= 0;
          t--
        )
          (e = r[t]),
            s.removeLayer(e),
            e._preSpiderfyLatlng &&
              (e.setLatLng(e._preSpiderfyLatlng), delete e._preSpiderfyLatlng),
            e.setZIndexOffset && e.setZIndexOffset(0),
            e._spiderLeg && (n.removeLayer(e._spiderLeg), delete e._spiderLeg);
        i.fire("unspiderfied", {
          cluster: this,
          markers: r,
        }),
          (i._ignoreMove = !1),
          (i._spiderfied = null);
      },
    }),
    (L.MarkerClusterNonAnimated = L.MarkerCluster.extend({
      _animationSpiderfy(e, t) {
        let i,
          n,
          s,
          r,
          o = this._group,
          a = o._map,
          l = o._featureGroup,
          h = this._group.options.spiderLegPolylineOptions;
        for (o._ignoreMove = !0, i = 0; i < e.length; i++)
          (r = a.layerPointToLatLng(t[i])),
            (n = e[i]),
            (s = new L.Polyline([this._latlng, r], h)),
            a.addLayer(s),
            (n._spiderLeg = s),
            (n._preSpiderfyLatlng = n._latlng),
            n.setLatLng(r),
            n.setZIndexOffset && n.setZIndexOffset(1e6),
            l.addLayer(n);
        this.setOpacity(0.3),
          (o._ignoreMove = !1),
          o.fire("spiderfied", {
            cluster: this,
            markers: e,
          });
      },
      _animationUnspiderfy() {
        this._noanimationUnspiderfy();
      },
    })),
    L.MarkerCluster.include({
      _animationSpiderfy(e, t) {
        let n,
          s,
          r,
          o,
          a,
          l,
          h = this,
          u = this._group,
          _ = u._map,
          d = u._featureGroup,
          c = this._latlng,
          p = _.latLngToLayerPoint(c),
          g = L.Path.SVG,
          f = L.extend({}, this._group.options.spiderLegPolylineOptions),
          m = f.opacity;
        for (
          m === i &&
            (m =
              L.MarkerClusterGroup.prototype.options.spiderLegPolylineOptions
                .opacity),
            g
              ? ((f.opacity = 0),
                (f.className = `${
                  f.className || ""
                } leaflet-cluster-spider-leg`))
              : (f.opacity = m),
            u._ignoreMove = !0,
            n = 0;
          n < e.length;
          n++
        )
          (s = e[n]),
            (l = _.layerPointToLatLng(t[n])),
            (r = new L.Polyline([c, l], f)),
            _.addLayer(r),
            (s._spiderLeg = r),
            g &&
              ((o = r._path),
              (a = o.getTotalLength() + 0.1),
              (o.style.strokeDasharray = a),
              (o.style.strokeDashoffset = a)),
            s.setZIndexOffset && s.setZIndexOffset(1e6),
            s.clusterHide && s.clusterHide(),
            d.addLayer(s),
            s._setPos && s._setPos(p);
        for (
          u._forceLayout(), u._animationStart(), n = e.length - 1;
          n >= 0;
          n--
        )
          (l = _.layerPointToLatLng(t[n])),
            (s = e[n]),
            (s._preSpiderfyLatlng = s._latlng),
            s.setLatLng(l),
            s.clusterShow && s.clusterShow(),
            g &&
              ((r = s._spiderLeg),
              (o = r._path),
              (o.style.strokeDashoffset = 0),
              r.setStyle({
                opacity: m,
              }));
        this.setOpacity(0.3),
          (u._ignoreMove = !1),
          setTimeout(function () {
            u._animationEnd(),
              u.fire("spiderfied", {
                cluster: h,
                markers: e,
              });
          }, 200);
      },
      _animationUnspiderfy(e) {
        let t,
          i,
          n,
          s,
          r,
          o,
          a = this,
          l = this._group,
          h = l._map,
          u = l._featureGroup,
          _ = e
            ? h._latLngToNewLayerPoint(this._latlng, e.zoom, e.center)
            : h.latLngToLayerPoint(this._latlng),
          d = this.getAllChildMarkers(),
          c = L.Path.SVG;
        for (
          l._ignoreMove = !0,
            l._animationStart(),
            this.setOpacity(1),
            i = d.length - 1;
          i >= 0;
          i--
        )
          (t = d[i]),
            t._preSpiderfyLatlng &&
              (t.setLatLng(t._preSpiderfyLatlng),
              delete t._preSpiderfyLatlng,
              (o = !0),
              t._setPos && (t._setPos(_), (o = !1)),
              t.clusterHide && (t.clusterHide(), (o = !1)),
              o && u.removeLayer(t),
              c &&
                ((n = t._spiderLeg),
                (s = n._path),
                (r = s.getTotalLength() + 0.1),
                (s.style.strokeDashoffset = r),
                n.setStyle({
                  opacity: 0,
                })));
        (l._ignoreMove = !1),
          setTimeout(function () {
            let e = 0;
            for (i = d.length - 1; i >= 0; i--) (t = d[i]), t._spiderLeg && e++;
            for (i = d.length - 1; i >= 0; i--)
              (t = d[i]),
                t._spiderLeg &&
                  (t.clusterShow && t.clusterShow(),
                  t.setZIndexOffset && t.setZIndexOffset(0),
                  (e > 1 || l.options.allowClustersOfOne) && u.removeLayer(t),
                  h.removeLayer(t._spiderLeg),
                  delete t._spiderLeg);
            l._animationEnd(),
              l.fire("unspiderfied", {
                cluster: a,
                markers: d,
              });
          }, 200);
      },
    }),
    L.MarkerClusterGroup.include({
      _spiderfied: null,
      unspiderfy() {
        this._unspiderfy.apply(this, arguments);
      },
      _spiderfierOnAdd() {
        this._map.on("click", this._unspiderfyWrapper, this),
          this._map.options.zoomAnimation &&
            this._map.on("zoomstart", this._unspiderfyZoomStart, this),
          this._map.on("zoomend", this._noanimationUnspiderfy, this),
          L.Browser.touch || this._map.getRenderer(this);
      },
      _spiderfierOnRemove() {
        this._map.off("click", this._unspiderfyWrapper, this),
          this._map.off("zoomstart", this._unspiderfyZoomStart, this),
          this._map.off("zoomanim", this._unspiderfyZoomAnim, this),
          this._map.off("zoomend", this._noanimationUnspiderfy, this),
          this._noanimationUnspiderfy();
      },
      _unspiderfyZoomStart() {
        this._map && this._map.on("zoomanim", this._unspiderfyZoomAnim, this);
      },
      _unspiderfyZoomAnim(e) {
        L.DomUtil.hasClass(this._map._mapPane, "leaflet-touching") ||
          (this._map.off("zoomanim", this._unspiderfyZoomAnim, this),
          this._unspiderfy(e));
      },
      _unspiderfyWrapper() {
        this._unspiderfy();
      },
      _unspiderfy(e) {
        this._spiderfied && this._spiderfied.unspiderfy(e);
      },
      _noanimationUnspiderfy() {
        this._spiderfied && this._spiderfied._noanimationUnspiderfy();
      },
      _unspiderfyLayer(e) {
        e._spiderLeg &&
          (this._featureGroup.removeLayer(e),
          e.clusterShow && e.clusterShow(),
          e.setZIndexOffset && e.setZIndexOffset(0),
          this._map.removeLayer(e._spiderLeg),
          delete e._spiderLeg);
      },
    }),
    L.MarkerClusterGroup.include({
      refreshClusters(e) {
        return (
          e
            ? e instanceof L.MarkerClusterGroup
              ? (e = e._topClusterLevel.getAllChildMarkers())
              : e instanceof L.LayerGroup
                ? (e = e._layers)
                : e instanceof L.MarkerCluster
                  ? (e = e.getAllChildMarkers())
                  : e instanceof L.Marker && (e = [e])
            : (e = this._topClusterLevel.getAllChildMarkers()),
          this._flagParentsIconsNeedUpdate(e),
          this._refreshClustersIcons(),
          this.options.singleMarkerMode &&
            this._refreshSingleMarkerModeMarkers(e),
          this
        );
      },
      _flagParentsIconsNeedUpdate(e) {
        let t, i;
        for (t in e)
          for (i = e[t].__parent; i; )
            (i._iconNeedsUpdate = !0), (i = i.__parent);
      },
      _refreshSingleMarkerModeMarkers(e) {
        let t, i;
        for (t in e)
          (i = e[t]),
            this.hasLayer(i) && i.setIcon(this._overrideMarkerIcon(i));
      },
    }),
    L.Marker.include({
      refreshIconOptions(e, t) {
        let i = this.options.icon;
        return (
          L.setOptions(i, e),
          this.setIcon(i),
          t && this.__parent && this.__parent._group.refreshClusters(this),
          this
        );
      },
    });
})(window, document);

const MarkerClusterGroup = L.MarkerClusterGroup;
const markerClusterGroup = L.markerClusterGroup;

export { markerClusterGroup, MarkerClusterGroup };
