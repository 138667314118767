import { makeAutoObservable } from "mobx";

function parseDate(value?: string) {
  if (value && value.length > 0) {
    if (value.match(/^\d{4}-\d{2}-\d{2}$/) && !value.startsWith("0")) {
      return parseInt(value.replaceAll("-", ""), 10);
    }
  }
  return undefined;
}

function formatDateString(date?: number) {
  if (!date) return undefined;
  const s = date.toString(10);
  return `${s.slice(0, 4)}-${s.slice(4, 6)}-${s.slice(6, 8)}`;
}

export class DateRangeFilter {
  field: string;

  min?: number = undefined;
  get minStr() {
    return formatDateString(this.min);
  }
  max?: number = undefined;
  get maxStr() {
    return formatDateString(this.max);
  }

  constructor(field: string) {
    makeAutoObservable(this);
    this.field = field;
  }

  clear() {
    this.min = undefined;
    this.max = undefined;
  }

  clearMin() {
    this.min = undefined;
  }

  clearMax() {
    this.max = undefined;
  }
  setMin(value?: string) {
    this.min = parseDate(value);
  }

  setMax(value?: string) {
    this.max = parseDate(value);
  }

  get hasFilter() {
    return !!this.min || !!this.max;
  }

  get filter() {
    if (this.min && this.max) {
      return `${this.field} ${this.min} TO ${this.max}`;
    }
    if (this.min) {
      return `${this.field} >= ${this.min}`;
    }
    if (this.max) {
      return `${this.field} <= ${this.max}`;
    }
    return "";
  }
}
